import * as React from "react";
import { RouteNames } from "./RouteNames";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { HomeSite } from "../sites/HomeSite";
import { AboutMeSite } from "../sites/AboutMeSite";
import { Navbar } from "../ui/Navbar";
import { CVSite } from "../sites/CVSite";
import { ContactSite } from "../sites/ContactSite";

export const AppRouter = () => (
  <BrowserRouter>
    <Navbar />
    <Routes>
      <Route path={RouteNames.ROOT} element={<HomeSite />} />
      <Route path={RouteNames.ABOUT_ME} element={<AboutMeSite />} />
      <Route path={RouteNames.CV} element={<CVSite />} />
      <Route path={RouteNames.CONTACT} element={<ContactSite />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);
