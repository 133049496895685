import * as React from "react";
import { AppRouter } from "../router/AppRouter";

export const AppContainerSite = () => {
  return (
    <div>
      <AppRouter />
    </div>
  );
};
